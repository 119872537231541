import { createSlice } from "@reduxjs/toolkit";

const MessagesSlice = createSlice({
  name: "messages",
  initialState: {
    messages: [],
    collapseMessages: [],
  },
  reducers: {
    setMessages: (state, action) => {
      state.messages = action.payload;
    },
    setCollapseMessages: (state, action) => {
      state.collapseMessages = action.payload;
    },
  },
});

export const { setMessages, setCollapseMessages } = MessagesSlice.actions;

export default MessagesSlice.reducer;
