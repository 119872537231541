import { configureStore } from "@reduxjs/toolkit";
import AuthSlice from "./Reducers/AuthSlice";
import LinkDeviceSlice from "./Reducers/LinkDeviceSlice";
import SettingSlice from "./Reducers/SettingSlice";
import DashboardSlice from "./Reducers/DashboardSlice";
import GallerySlice from "./Reducers/GallerySlice";
import GroupsSlice from "./Reducers/GroupsSlice";
import SubscriptionSlice from "./Reducers/SubscriptionSlice";
import MessagesSlice from "./Reducers/MessagesSlice";
import CustomerListSlice from "./Reducers/CustomerListSlice";

const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
};
const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (e) {
    // Ignore write errors;
  }
};
const preloadedState = loadState();
const store = configureStore({
  reducer: {
    auth: AuthSlice,
    linkDevice: LinkDeviceSlice,
    settings: SettingSlice,
    dashboard: DashboardSlice,
    gallery: GallerySlice,
    groups: GroupsSlice,
    subscriptions: SubscriptionSlice,
    messages: MessagesSlice,
    customerLists: CustomerListSlice,
  },
  preloadedState,
  // enhancers: [window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()],
});
export default store;
store.subscribe(() => {
  saveState(store.getState());
});
