import React, { Suspense } from "react";
import Loading from "./Loading";
import { CircularProgress } from "@mui/material";

const WithLazyComponent = (LazyComponent) => {
  return (
    <Suspense
      fallback={
        <div
          className="loadingContainer admin_back"
          style={{ minHeight: "100vh" }}
        >
          <CircularProgress size={70} sx={{ color: "black", mt: 22 }} />
        </div>
      }
    >
      <LazyComponent />
    </Suspense>
  );
};
export default WithLazyComponent;
