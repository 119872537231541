import { createSlice } from "@reduxjs/toolkit";

const CustomerListSlice = createSlice({
  name: "customerLists",
  initialState: {
    customerLists: [],
    customerList: {},
    contacts: [],
  },
  reducers: {
    setCustomerLists: (state, action) => {
      state.customerLists = action.payload;
    },
    setCustomerList: (state, action) => {
      state.customerList = action.payload;
    },
    setContacts: (state, action) => {
      state.contacts = action.payload;
    },
  },
});

export const { setCustomerLists, setCustomerList,setContacts } = CustomerListSlice.actions;

export default CustomerListSlice.reducer;
