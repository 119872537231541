import { createSlice } from "@reduxjs/toolkit";

const SubscriptionSlice = createSlice({
  name: "subscriptions",
  initialState: {
    subscriptions: [],
    plans: [],
  },
  reducers: {
    setSubscriptions: (state, action) => {
      state.subscriptions = action.payload;
    },
    setPlans: (state, action) => {
      state.plans = action.payload;
    },
  },
});

export const { setSubscriptions, setPlans } = SubscriptionSlice.actions;

export default SubscriptionSlice.reducer;
