import "./App.css";
import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import router from "./Router";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useCookies } from "react-cookie";

function App() {
  const [cookies, setCookie] = useCookies(["lang"]);
  const theme = createTheme({
    typography: {
      fontFamily: ` "Cairo", sans-serif`,
    },
  });


  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <RouterProvider router={router} />
      </div>
    </ThemeProvider>
  );
}

export default App;
