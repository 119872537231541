import { createSlice } from "@reduxjs/toolkit";

const GroupsSlice = createSlice({
  name: "groups",
  initialState: {
    groups: [],
    groupData: [],
    groupName: {},
  },
  reducers: {
    setGroups: (state, action) => {
      state.groups = action.payload;
    },
    setGroupDetails: (state, action) => {
      state.groupData = action.payload;
    },
    setGroupName: (state, action) => {
      state.groupName = action.payload;
    },
  },
});

export const { setGroups, setGroupDetails,setGroupName } = GroupsSlice.actions;

export default GroupsSlice.reducer;
