import { createSlice } from "@reduxjs/toolkit";

const GallerySlice = createSlice({
  name: "gallery",
  initialState: {
    gallery: [],
    galleryMsg: { open: false, message: "" },
  },
  reducers: {
    setGallery: (state, action) => {
      state.gallery = action.payload;
    },
    setGalleryMsg: (state, action) => {
      state.galleryMsg = action.payload;
    },
  },
});

export const { setGallery, setGalleryMsg } = GallerySlice.actions;

export default GallerySlice.reducer;
