import { lazy } from "react";
import WithLazyComponent from "../shared/components/WithLazyComponent";
import DashboardIcon from "@mui/icons-material/Dashboard";
import i18next from "../i18n/config";
import GroupsIcon from "@mui/icons-material/Groups";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import MailIcon from "@mui/icons-material/Mail";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import TuneIcon from "@mui/icons-material/Tune";
import SellIcon from "@mui/icons-material/Sell";
import CodeIcon from "@mui/icons-material/Code";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ContactsIcon from '@mui/icons-material/Contacts';

const DashboardIndex = WithLazyComponent(
  lazy(() => import("./Dashboard/index"))
);
const SendMsgIndex = WithLazyComponent(lazy(() => import("./SendMsg")));
const LinkIndex = WithLazyComponent(lazy(() => import("./LinkDevice/index")));
const SettingIndex = WithLazyComponent(lazy(() => import("./Settings/index")));
const GroupsIndex = WithLazyComponent(lazy(() => import("./Groups/index")));
const CustomerListIndex = WithLazyComponent(
  lazy(() => import("./CustomerLists/index"))
);
const SubscriptionsIndex = WithLazyComponent(
  lazy(() => import("./Subscription/index"))
);

const AdminRoutes = [
  {
    path: "/admin/dashboard",
    icon: LeaderboardIcon,
    label: "dashboard",
    children: [
      { index: true, element: DashboardIndex },
      //   { path: "create", element: Create },
    ],
  },
  {
    path: "/admin/link-device",
    icon: InsertLinkIcon,
    label: "link_device",
    children: [
      { index: true, element: LinkIndex },
      //   { path: "create", element: Create },
    ],
  },
  {
    path: "/admin/groups",
    icon: GroupsIcon,
    label: "groups",
    children: [
      { index: true, element: GroupsIndex },
      //   { path: "create", element: Create },
    ],
  },
  {
    path: "/admin/send-message",
    icon: MailOutlineIcon,
    label: "send_message",
    children: [
      { index: true, element: SendMsgIndex },
      { path: "/admin/send-message/individual", element: SendMsgIndex },
      { path: "/admin/send-message/group", element: SendMsgIndex },
      { path: "/admin/send-message/bulk", element: SendMsgIndex },
      { path: "/admin/send-message/send-group-bulk", element: SendMsgIndex },
      { path: "/admin/send-message/custom", element: SendMsgIndex },

    ],
    subModules: [
      {
        path: "/admin/send-message/individual",
        name: "individual",
        label: "individual",
      },
      // {
      //   path: "/admin/send-message/group",
      //   name: "group",
      //   label: "group",
      // },
      {
        path: "/admin/send-message/bulk",
        name: "bulk",
        label: "bulk",
      },
      {
        path: "/admin/send-message/send-group-bulk",
        name: "group_bulk",
        label: "group_bulk",
      },
      {
        path: "/admin/send-message/custom",
        name: "custom",
        label: "custom",
      },
    ],
  },
  {
    path: "/admin/message-report",
    icon: AssessmentIcon,
    label: "message_report",
    children: [{ index: true, element: SendMsgIndex }],
  },

  {
    path: "/admin/subscriptions",
    icon: SellIcon,
    label: "subscriptions",
    children: [{ index: true, element: SubscriptionsIndex }],
  },
  {
    path: "/admin/customer-lists",
    icon: ContactsIcon,
    label: "customer_lists",
    children: [
      { index: true, element: CustomerListIndex },
      { path: "/admin/customer-lists/contacts/:id", element: CustomerListIndex },
    ],
  },
  {
    path: "/admin/settings",
    icon: TuneIcon,
    label: "settings",
    children: [{ index: true, element: SettingIndex }],
  },

  // {
  //   path: "*",
  //   // element: HomeIndex,
  // },
];

export default AdminRoutes;
