import { createSlice } from "@reduxjs/toolkit";

const LinkDeviceSlice = createSlice({
  name: "linkDevice",
  initialState: {
    whatsStatus: false,
    qrImage: {},
    linkedDevice: {},
    linkedDevices: [],
  },
  reducers: {
    setWhatsStatus: (state, action) => {
      state.whatsStatus = action.payload;
    },
    setQrImage: (state, action) => {
      state.qrImage = action.payload;
    },
    setLinkedDevice: (state, action) => {
      state.linkedDevice = action.payload;
    },
    setLinkedDevices: (state, action) => {
      state.linkedDevices = action.payload;
    },
  },
});

export const { setWhatsStatus, setQrImage, setLinkedDevice, setLinkedDevices } =
  LinkDeviceSlice.actions;

export default LinkDeviceSlice.reducer;
