import { createSlice } from "@reduxjs/toolkit";

const SettingSlice = createSlice({
  name: "settings",
  initialState: {
    settings: {},
    setting: {},
  },
  reducers: {
    setSettings: (state, action) => {
      state.settings = action.payload;
    },
    setSetting: (state, action) => {
      state.setting = action.payload;
    },
  },
});

export const { setSettings, setSetting } = SettingSlice.actions;

export default SettingSlice.reducer;
