import { createSlice } from "@reduxjs/toolkit";

const AuthSlice = createSlice({
  name: "auth",
  initialState: {
    token: "",
    api_key: "",
    session_id: "",
    id: "",
    password: "",
    name: "",
    email: "",
    mobile_number: "",
    country_code: "",
    dob: "",
    photo: "",
    user_type: "",
    lang: "en",
    is_admin: false,
    is_subscribed: 'Inactive',
    message: {
      open: false,
      type: "",
      message: "",
    },
  },
  reducers: {
    setSession: (state, action) => {
      state.session_id = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },

    setName: (state, action) => {
      state.name = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setMobile: (state, action) => {
      state.mobile_number = action.payload;
    },
    setCountryCode: (state, action) => {
      state.country_code = action.payload;
    },
    setDob: (state, action) => {
      state.dob = action.payload;
    },
    setPhoto: (state, action) => {
      state.photo = action.payload;
    },
    setUserType: (state, action) => {
      state.user_type = action.payload;
    },
    setLang: (state, action) => {
      state.lang = action.payload;
    },
    setMessage: (state, action) => {
      state.message = action.payload;
    },
    setIsAdmin: (state, action) => {
      state.is_admin = action.payload;
    },
    setApiKey: (state, action) => {
      state.api_key = action.payload;
    },
    setIsSubscribed: (state, action) => {
      state.is_subscribed = action.payload;
    },
  },
});

export const {
  setSession,
  setToken,
  setName,
  setEmail,
  setMobile,
  setCountryCode,
  setDob,
  setPhoto,
  setUserType,
  setLang,
  setMessage,
  setIsAdmin,
  setApiKey,
  setIsSubscribed,
} = AuthSlice.actions;
export default AuthSlice.reducer;
