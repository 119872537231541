import { createSlice } from "@reduxjs/toolkit";

const DashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    dashboard: {},
  },
  reducers: {
    setDashboard: (state, action) => {
      state.dashboard = action.payload;
    },
  },
});

export const { setDashboard } = DashboardSlice.actions;

export default DashboardSlice.reducer;
